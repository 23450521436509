import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Spin} from 'antd';
import {Line} from '@ant-design/charts';
import './ConvertibleBond.css';
import moment from "moment";

function ConvertibleBond(props) {
    const [loading, setLoading] = useState(true);
    const [dblows, setDblows] = useState([]);

    const refreshData = () => {
        setLoading(true);
        const url = `/getconvertiblebondindexhistory`;

        axios.get(url)
            .then(response => {
                const data = response.data;
                console.log(data)
                const dblows = data.map(datum => {
                    return {
                        日期: datum.price_dt,
                        维度: '指数',
                        值: datum.avg_dblow,
                    }
                });
                setDblows(dblows);
                setLoading(false);
            })
    }

    useEffect(() => {
        if (props.shouldLoad) {
            refreshData();
        }
    }, [props.shouldLoad])

    const annotations = [];

    let current;
    if (dblows.length > 0) {
        current = dblows[dblows.length - 1];
        annotations.push({
            type: 'text',
            content: current.值 + '\n' + current.日期,
            position: [current.日期, current.值],
            offsetX: '-200%',
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'line',
            start: ['min', current.值],
            end: ['max', current.值],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
                stroke: 'red',
            },
        });
    }

    const config = {
        data: dblows,
        xField: '日期',
        yField: '值',
        seriesField: '维度',
        autoFit: true,
        lineStyle: {
            lineWidth: 1,
        },
        legend: false,
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: 120,
        },
        xAxis: {
            label: null,
            line: null,
            tickLine: null,
        },
        annotations: annotations,
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + defaultContent.toFixed(4);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
        },
        meta: {
            ['日期']: {
                sync: false,
            },
        },
    };

    return (
        <div id='container-convertible-bond'>
            <div id='titlebar-convertible-bond'>
                <span id='title-convertible-bond' style={{display: 'inline-block'}}>转债指数</span>
                <span id='title-tail-convertible-bond'>{current && moment(current.日期).format('(MM/DD)')}</span>
            </div>
            <div id='subtitlebar-convertible-bond'>
                <h3 id='subtitle-convertible-bond' style={{display: 'inline-block'}}>{current && current.值}</h3>
            </div>
            <div id='dblow-chart-container'>
                <Spin id='spin-dblow-chart' size='middle' spinning={loading}>
                    <Line id='dblow-chart' {...config}/>
                </Spin>
            </div>
        </div>
    );
}

export default ConvertibleBond;
