import React, {useState} from 'react';
import {DatePicker, Grid} from "antd";
import moment from 'moment';
import NationalBondHistoryChart from "./NationalBondHistoryChart";

const {useBreakpoint} = Grid;

function NationalBondHistory() {
    const [dateRange, setDateRange] = useState({fromDate: moment('1970-01-01'), toDate: moment()});

    const screens = useBreakpoint();

    return (
        <div style={{padding: screens['sm'] ? 24 : 8, height: '100%'}}>
            <div>
                <DatePicker.RangePicker
                    id='datePicker'
                    defaultValue={[dateRange.fromDate, dateRange.toDate]}
                    ranges={{
                        '今年': [moment().startOf('year'), moment()],
                        '一年内': [moment().add(-1, 'years'), moment()],
                        '三年内': [moment().add(-3, 'years'), moment()],
                        '五年内': [moment().add(-5, 'years'), moment()],
                        '十年内': [moment().add(-10, 'years'), moment()],
                        '历史所有': [moment('1970'), moment()],
                    }}
                    format="YYYY/MM/DD"
                    onChange={(date) => {
                        setDateRange({fromDate: date[0], toDate: date[1]});
                        document.getElementById('datePicker').blur();
                    }}
                    inputReadOnly
                />
            </div>
            <div style={{marginTop: screens['sm'] ? 24 : 8, backgroundColor: '#fcfcfc', padding: screens['sm'] ? '24px 16px' : 0}}>
                <NationalBondHistoryChart fromDate={dateRange.fromDate} toDate={dateRange.toDate}/>
            </div>
        </div>
    );
}

export default NationalBondHistory;
