import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from "antd";
import axios from 'axios';

function loadComparisons(code, setComparisonsLoading, setComparisons, setBaseLine, setHighExtremes, setLowExtremes) {
    if (!code) {
        return;
    }
    setComparisonsLoading(true);
    setComparisons([]);
    setBaseLine(0);
    let url = '/stock_pond_comparison';
    axios.get(url, {
        params: {
            code: code,
        }
    }).then(response => {
        const data = response.data;

        const comparisons = data.comparisons.map(datum => {
            return {
                日期: datum.date,
                维度: '利比',
                值: datum.value,
            }
        });

        setComparisons(comparisons);
        setHighExtremes(data.high_extremes);
        setLowExtremes(data.low_extremes);
        setBaseLine(data.base);
        setComparisonsLoading(false);
    });
}

function buildChart(comparisonsLoading, comparisons, baseLine, highExtremes, lowExtremes) {
    const maxValue = Math.max(...comparisons.map(h => h.值 === null ? -999999 : h.值));
    const minValue = Math.min(...comparisons.map(h => h.值 === null ? 999999 : h.值));
    const yPadding = (maxValue - minValue) * 0.05;

    const annotations = [];
    const lineColor = new Map();
    lineColor.set(0, 'red');
    lineColor.set(1, 'orange');
    lineColor.set(2, 'blue');
    lineColor.set(3, 'green');
    lineColor.set(4, 'green');
    for (let [i, color] of lineColor) {
        let line = baseLine + i * 0.5;
        annotations.push({
            type: 'line',
            top: true,
            start: ['min', line],
            end: ['max', line],
            style: {
                lineWidth: 1,
                lineDash: [5, 5],
                stroke: color,
            },
        }, {
            type: 'text',
            position: ['min', line],
            content: line,
            offsetY: -4,
            style: {
                textBaseline: 'bottom',
                fill: color,
            },
        })
    }

    if (comparisons.length > 0) {
        const max = comparisons.reduce((prev, current) => current.值 > prev.值 ? current : prev);
        const min = comparisons.reduce((prev, current) => current.值 < prev.值 ? current : prev);
        const current = comparisons[comparisons.length - 1];
        annotations.push({
            type: 'text',
            content: max.值,
            position: [max.日期, max.值],
            offsetY: -8,
            style: {
                opacity: 0.5
            }
        });
        annotations.push({
            type: 'text',
            content: min.值,
            position: [min.日期, min.值],
            offsetY: 8,
            style: {
                opacity: 0.5
            }
        });
        annotations.push({
            type: 'text',
            content: current.值 + '\n' + current.日期,
            position: [current.日期, current.值],
            offsetX: '-100%',
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'line',
            start: ['min', current.值],
            end: ['max', current.值],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
            },
        });

        for (let [d, v] of highExtremes) {
            if (d === min.日期 || d === max.日期 || d === current.日期) {
                continue;
            }
            annotations.push({
                type: 'text',
                content: v,
                position: [d, v],
                offsetY: -8,
                style: {
                    fontWeight: 'light',
                    opacity: 0.5
                }
            });
        }

        for (let [d, v] of lowExtremes) {
            if (d === min.日期 || d === max.日期 || d === current.日期) {
                continue;
            }
            annotations.push({
                type: 'text',
                content: v,
                position: [d, v],
                offsetY: 8,
                style: {
                    fontWeight: 'light',
                    opacity: 0.5
                }
            });
        }
    }

    const config = {
        data: comparisons,
        xField: '日期',
        yField: '值',
        seriesField: '维度',
        lineStyle: {
            lineWidth: 1,
        },
        legend: false,
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: 0,
            maxLimit: isFinite(yPadding) ? maxValue + yPadding : 0,
        },
        xAxis: {
            line: comparisons.length > 0 ? {
                style: {
                    stroke: '#e6e6e6'
                }
            } : null,
            tickLine: null,
            tickMethod: scale => {
                const ticks = [];

                let prevDate = new Date(scale.values[0]);
                scale.values.forEach(dateString => {
                    const d = new Date(dateString);
                    if (d.getFullYear() !== prevDate.getFullYear()) {
                        ticks.push(dateString);
                        prevDate = d;
                    }
                });

                return ticks;
            },
            label: {
                formatter: date => new Date(date).getFullYear(),
            },
        },
        annotations: annotations,
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + defaultContent.toFixed(4);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
        },
        padding: [0, 70, 60, 10],
        slider: {
            start: 0,
            end: 1,
        },
        limitInPlot: false,
        meta: {
            ['日期']: {
                sync: false,
            },
        },
    };
    return (
        <div>
            <Spin size='middle' spinning={comparisonsLoading}>
                <Line {...config}/>
            </Spin>
        </div>
    );
}

function ComparisonChart(props) {
    const [comparisonsLoading, setComparisonsLoading] = useState(false);
    const [comparisons, setComparisons] = useState([]);
    const [highExtremes, setHighExtremes] = useState([]);
    const [lowExtremes, setLowExtremes] = useState([]);
    const [baseLine, setBaseLine] = useState(0);

    useEffect(() => {
        loadComparisons(props.code, setComparisonsLoading, setComparisons, setBaseLine, setHighExtremes, setLowExtremes)
    }, [props.code]);

    return buildChart(comparisonsLoading, comparisons, baseLine, highExtremes, lowExtremes);
}

export default ComparisonChart;
