import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Spin} from 'antd';
import './HoldingsBrief.css';

function format_signed_percentage(percentage) {
    if (!percentage && percentage !== 0) {
        return '';
    }
    let value_text = percentage.toFixed(2).toString() + '%';
    if (percentage >= 0) {
        value_text = '+' + value_text;
    }
    return value_text;
}

function signNumber(number) {
    if (!number && number !== 0) {
        return '';
    }
    if (number >= 0) {
        return '+' + number.toString();
    } else {
        return number.toString();
    }
}

function HoldingsBrief(props) {
    const [loading, setLoading] = useState(true);
    const [profit, setProfit] = useState([]);

    const refreshData = () => {
        setLoading(true);
        const url = `/holdingsprofit`;

        axios.get(url)
            .then(response => {
                const data = response.data;
                setProfit(data);
                setLoading(false);
            })
    };

    useEffect(() => {
        if (props.shouldLoad) {
            refreshData();
        }
    }, [props.shouldLoad]);

    const colorToday = profit.earnings_today_percentage >= 0 ? '#b54343' : '#3b8a36';
    const colorTotal = profit.dynamic_earnings_percentage >= 0 ? '#b54343' : '#3b8a36';

    return (
        <div id='container-holdings-brief'>
            <div id='titlebar-holdings-brief'>
                <h2 id='title-holdings-brief' style={{display: 'inline-block'}}>持仓简报</h2>
            </div>
            <Spin id='spin-comparison-chart' size='middle' spinning={loading}>
                <div id='main-section-holdings-brief'>
                    <h3 id='holdings-brief-earnings_today'
                        style={{display: 'inline-block', color: colorToday}}>
                        {signNumber(profit.earnings_today)}
                    </h3>
                    <span id='holdings-brief-earnings_today_percentage'
                          style={{display: 'inline-block', color: colorToday}}>
                        {format_signed_percentage(profit.earnings_today_percentage)}
                    </span>
                </div>
                <div id='subtitlebar-holdings-brief'>
                    <h4 id='subtitle-holdings-brief' style={{display: loading ? 'none' : 'inline-block'}}>
                        总和
                    </h4>
                </div>
                <div id='minor-section-holdings-brief'>
                    <h3 id='holdings-brief-total_amount'
                        style={{display: 'inline-block', color: colorTotal}}>
                        {profit.total_amount}
                    </h3>
                    <span id='holdings-brief-dynamic_earnings_percentage'
                          style={{display: 'inline-block', color: colorTotal}}>
                        {format_signed_percentage(profit.dynamic_earnings_percentage)}
                    </span>
                </div>
            </Spin>
        </div>
    );
}

export default HoldingsBrief;
