import React from 'react';
import {DualAxes} from '@ant-design/charts';
import {Spin} from "antd";

function format_percentage(percentage) {
    percentage = Number.parseFloat(percentage);
    let value_text = ((percentage - 1) * 100).toFixed(2).toString() + '%';
    if (percentage >= 1) {
        value_text = '+' + value_text;
    }
    return value_text;
}

function buildChart(historiesLoading, percentageData, amountData, onChartPrepared) {
    const annotations = [
        {
            type: 'line',
            top: true,
            start: ['min', 1],
            end: ['max', 1],
            style: {
                lineWidth: 1,
                lineDash: [5, 5],
            },
        },
    ];
    if (percentageData.length > 0) {
        const max = percentageData.reduce((prev, current) => current.比例 > prev.比例 && current.比例 < 2 ? current : prev);
        const min = percentageData.reduce((prev, current) => current.比例 < prev.比例 ? current : prev);
        const current = percentageData[percentageData.length - 1];
        annotations.push({
            type: 'text',
            top: true,
            content: format_percentage(max.比例),
            position: [max.日期, max.比例],
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'text',
            top: true,
            content: format_percentage(min.比例),
            position: [min.日期, min.比例],
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'text',
            top: true,
            content: format_percentage(current.比例),
            position: [current.日期, current.比例],
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'line',
            top: true,
            start: ['min', current.比例],
            end: ['max', current.比例],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
            },
        });
    }

    const config = {
        data: [percentageData, amountData],
        xField: '日期',
        yField: ['比例', '金额'],
        padding: [24, 24, 60, 24],
        height: 600,
        geometryOptions: [
            {
                geometry: 'line',
                seriesField: '维度',
                color: 'red',
                lineStyle: {
                    lineWidth: 1,
                },
            },
            {
                geometry: 'line',
                seriesField: '维度',
                color: ['blue', 'green'],
                lineStyle: {
                    lineWidth: 1,
                    lineDash: [3, 3],
                },
            },
        ],
        yAxis: {
            比例: {
                // maxLimit: 1.3,
                label: {
                    formatter: percentage => format_percentage(percentage),
                },
                grid: {
                    line: null,
                },
            },
            金额: {
                label: null,
                grid: {
                    line: null,
                },
            },
        },
        xAxis: {
            line: percentageData.length > 0 ? {
                style: {
                    stroke: '#e6e6e6'
                }
            } : null,
            tickLine: null,
            tickMethod: scale => {
                const ticks = [];

                let prevDate = new Date(scale.values[0]);
                scale.values.forEach(dateString => {
                    const d = new Date(dateString);
                    if (d.getMonth() !== prevDate.getMonth()) {
                        ticks.push(dateString);
                        prevDate = d;
                    }
                });

                return ticks;
            },
            label: {
                formatter: date => new Date(date).getFullYear() + '-' + (new Date(date).getMonth() + 1).toString().padStart(2, '0'),
            },
        },
        annotations: {
            比例: annotations,
        },
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + format_percentage(defaultContent);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
            formatter: (datum) => {
                return {name: datum.维度, value: datum.比例 ? format_percentage(datum.比例) : datum.金额.toFixed(2)};
            },
        },
        slider: {
            start: 51 / percentageData.length,
            end: 1,
        },
        limitInPlot: false,
        meta: {
            ['日期']: {
                sync: false,
            },
        },
        theme: {
           background: '#fcfcfc',
        },
    };
    return (
        <div>
            <Spin size='middle' spinning={historiesLoading}>
                <DualAxes {...config} onReady={(chartInstance) => {
                    onChartPrepared(chartInstance);
                }}/>
            </Spin>
        </div>
    );
}

function HoldingsProfitHistoryChart(props) {
    const percentageData = props.histories.map(datum => {
        return {
            日期: datum.date,
            维度: '盈亏比例',
            比例: datum.holdings_percentage,
        };
    });
    const amountData = [].concat(
        props.histories.map(datum => {
            return {
                日期: datum.date,
                维度: '动态持仓',
                金额: datum.dynamic_amount,
            }
        })
    ).concat(
        props.histories.map(datum => {
            return {
                日期: datum.date,
                维度: '静态持仓',
                金额: datum.static_amount,
            }
        })
    );
    return buildChart(props.historiesLoading, percentageData, amountData, props.onChartPrepared);
}

export default HoldingsProfitHistoryChart;
