import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table} from "antd";
import './TradeRecord.css';

const {Column} = Table;

function formatPercentage(percentage) {
    if (percentage === Infinity) {
        return '+∞%';
    }

    let sign = '';
    if (percentage >= 0) {
        sign = '+';
    }
    return sign + (percentage * 100).toFixed(2).toString() + '%';
}

function TradeRecord() {
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        setLoading(true);
        const url = '/traderecords';
        axios.get(url)
            .then(response => {
                const data = response.data;
                setRecords(data);
                setLoading(false);
            })
    }, []);

    const names = [...new Set(records.map(i => i['property']['local_name']))];
    names.sort();
    const filters = names.map(i => {
        return {'text': i, 'value': i}
    });

    return (
        <div id='container-trade-record'>
            <Table
                dataSource={records}
                loading={loading}
                pagination={false}
                size={'small'}
                style={{height: '100%', overflow: 'auto'}}
            >
                <Column title="操作" dataIndex="purchase"
                        width={'8%'}
                        render={(i, r) => <span className={i ? 'td-purchase' : 'td-sell'}>
                        {i ? (r['amount'] === 0 ? '红利再投' : '买入') : (r['shares'] === 0 ? '现金分红' : '卖出')}
                    </span>}/>
                <Column title="日期" dataIndex="date"
                        width={'10%'}/>
                <Column title="代码" dataIndex={['property', 'code']}
                        width={'10%'}/>
                <Column title="品种" dataIndex={['property', 'local_name']}
                        width={'10%'}
                        filters={filters}
                        onFilter={(i, r) => {
                            return r['property']['local_name'].indexOf(i) === 0;
                        }}
                        render={i => <b>{i}</b>}/>
                <Column title="平台" dataIndex="platform"/>
                <Column title="净值" dataIndex="price"/>
                <Column title="份数" dataIndex="shares"/>
                <Column title="金额" dataIndex="amount"/>
                <Column title="手续费" dataIndex="fee"/>
                <Column title="当前价格" dataIndex="current_price"
                        render={(i, r) => {
                            return (
                                <span>
                                <span>{i}</span>
                                <span>({formatPercentage(r['current_price'] / r['price'] - 1)})</span>
                            </span>
                            )
                        }}/>
            </Table>
        </div>
    )
}

export default TradeRecord;
