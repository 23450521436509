import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Grid, Radio} from "antd";
import {CameraOutlined} from '@ant-design/icons';
import moment from 'moment';
import HoldingsProfitHistoryChart from "./HoldingsProfitHistoryChart";
import axios from "axios";

const {useBreakpoint} = Grid;

let chart;

function updateHistory(setHistories, history) {
    if (history === null) {
        return;
    }
    setHistories(history);
}

function HoldingsProfitHistory() {
    const [historiesLoading, setHistoriesLoading] = useState(true);
    const [histories, setHistories] = useState([]);
    const [selectedDataType, setSelectedDataType] = useState('all');

    const screens = useBreakpoint();

    useEffect(() => {
        setHistoriesLoading(true);
        const url = '/holdingsprofithistory';
        axios.get(url, {
            params: {
                type: selectedDataType,
            }
        }).then(response => {
            const data = response.data;
            updateHistory(setHistories, data);
            setHistoriesLoading(false);
        })
    }, [selectedDataType]);

    useEffect(() => {
        updateHistory(setHistories, histories);
    }, []);

    const onChartPrepared = (c) => {
        chart = c;
    };

    return (
        <div style={{padding: screens['sm'] ? 24 : 8, height: '100%'}}>
            <div>
                <Radio.Group onChange={(value) => setSelectedDataType(value.target.value)} defaultValue="all">
                    <Radio.Button value="all">总体</Radio.Button>
                    <Radio.Button value="stock">股票</Radio.Button>
                    <Radio.Button value="fund">基金</Radio.Button>
                </Radio.Group>
                <Button type="dashed" shape="circle" icon={<CameraOutlined/>} style={{marginLeft: 10}}
                        onClick={e => {
                            if (chart) {
                                chart.downloadImage("持仓收益历史.png");
                            }
                        }}/>
            </div>
            <div style={{
                marginTop: screens['sm'] ? 24 : 8,
                backgroundColor: '#fcfcfc',
                padding: screens['sm'] ? '24px 16px' : 0,
                height: 600,
            }}>
                <HoldingsProfitHistoryChart
                    historiesLoading={historiesLoading}
                    histories={histories}
                    onChartPrepared={onChartPrepared}
                />
            </div>
        </div>
    );
}

export default HoldingsProfitHistory;