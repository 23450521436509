import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from "antd";
import axios from 'axios';

function loadHistories(fromDate, toDate, setHistoriesLoading, setHistories, setHighExtremes, setLowExtremes) {
    setHistoriesLoading(true);
    setHistories([]);
    let url = '/nationalbondhistory';
    axios.get(url, {
        params: {
            from_date: fromDate.format('YYYY-MM-DD'),
            to_date: toDate.format('YYYY-MM-DD'),
        }
    }).then(response => {
        const data = response.data;

        const valueData = data.histories.map(datum => {
            return {
                日期: datum.date,
                维度: '收益率',
                收益率: datum.value,
            }
        });

        setHistories(valueData);
        setHighExtremes(data.high_extremes);
        setLowExtremes(data.low_extremes);
        setHistoriesLoading(false);
    });
}

function buildChart(historiesLoading, histories, highExtremes, lowExtremes) {
    const max = Math.max(...histories.map(h => h.收益率 === null ? -999999 : h.收益率));
    const min = Math.min(...histories.map(h => h.收益率 === null ? 999999 : h.收益率));
    const yPadding = (max - min) * 0.05;

    const annotations = [];
    const lineColor = new Map();
    lineColor.set(0, 'green');
    lineColor.set(1, 'blue');
    lineColor.set(2, 'orange');
    lineColor.set(3, 'red');
    lineColor.set(4, 'black');

    const baseLine = 2;

    for (let [i, color] of lineColor) {
        let line = baseLine + i * 0.5;
        annotations.push({
            type: 'line',
            top: true,
            start: ['min', line],
            end: ['max', line],
            style: {
                lineWidth: 1,
                lineDash: [5, 5],
                stroke: color,
            },
        }, {
            type: 'text',
            position: ['min', line],
            content: line,
            offsetY: -4,
            style: {
                textBaseline: 'bottom',
                fill: color,
            },
        })
    }

    if (histories.length > 0) {
        const max = histories.reduce((prev, current) => current.收益率 > prev.收益率 ? current : prev);
        const min = histories.reduce((prev, current) => current.收益率 < prev.收益率 ? current : prev);
        const current = histories[histories.length - 1];
        annotations.push({
            type: 'text',
            content: max.收益率,
            position: [max.日期, max.收益率],
            offsetY: -8,
            style: {
                opacity: 0.5
            }
        });
        annotations.push({
            type: 'text',
            content: min.收益率,
            position: [min.日期, min.收益率],
            offsetY: 8,
            style: {
                opacity: 0.5
            }
        });
        annotations.push({
            type: 'text',
            content: current.收益率 + '\n' + current.日期,
            position: [current.日期, current.收益率],
            offsetX: '-100%',
            style: {
                fill: 'red',
            }
        });
        annotations.push({
            type: 'line',
            start: ['min', current.收益率],
            end: ['max', current.收益率],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
            },
        });

        for (let [d, v] of highExtremes) {
            if (d === min.日期 || d === max.日期 || d === current.日期) {
                continue;
            }
            annotations.push({
                type: 'text',
                content: v,
                position: [d, v],
                offsetY: -8,
                style: {
                    fontWeight: 'light',
                    opacity: 0.5
                }
            });
        }

        for (let [d, v] of lowExtremes) {
            if (d === min.日期 || d === max.日期 || d === current.日期) {
                continue;
            }
            annotations.push({
                type: 'text',
                content: v,
                position: [d, v],
                offsetY: 8,
                style: {
                    fontWeight: 'light',
                    opacity: 0.5
                }
            });
        }
    }

    const config = {
        data: histories,
        xField: '日期',
        yField: '收益率',
        seriesField: '维度',
        lineStyle: {
            lineWidth: 1,
        },
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: isFinite(yPadding) ? min - yPadding : 0,
            maxLimit: isFinite(yPadding) ? max + yPadding : 0,
        },
        xAxis: {
            line: histories.length > 0 ? {
                style: {
                    stroke: '#e6e6e6'
                }
            } : null,
            tickLine: null,
            tickMethod: scale => {
                const ticks = [];

                let prevDate = new Date(scale.values[0]);
                scale.values.forEach(dateString => {
                    const d = new Date(dateString);
                    if (d.getFullYear() !== prevDate.getFullYear()) {
                        ticks.push(dateString);
                        prevDate = d;
                    }
                });

                return ticks;
            },
            label: {
                formatter: date => new Date(date).getFullYear(),
            },
        },
        annotations: annotations,
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + defaultContent.toFixed(4);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
        }
    };
    return (
        <div>
            <Spin size='middle' spinning={historiesLoading}>
                <Line {...config}/>
            </Spin>
        </div>
    );
}

function NationalBondHistoryChart(props) {
    const [historiesLoading, setHistoriesLoading] = useState(false);
    const [histories, setHistories] = useState([]);
    const [highExtremes, setHighExtremes] = useState([]);
    const [lowExtremes, setLowExtremes] = useState([]);

    useEffect(() => {
        loadHistories(props.fromDate, props.toDate, setHistoriesLoading, setHistories, setHighExtremes, setLowExtremes)
    }, [props.fromDate, props.toDate]);

    return buildChart(historiesLoading, histories, highExtremes, lowExtremes);
}

export default NationalBondHistoryChart;
