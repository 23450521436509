import React, {useState} from 'react';
import {Button, Card, Col, Row} from "antd";
import './Dashboard.css';
import StockPondComparison from "./stock_pond_comparison/StockPondComparison";
import ConvertibleBond from "./convertible_bond/ConvertibleBond";
import ConvertibleBondWatchdog from "./convertible_bond_watchdog/ConvertibleBondWatchdog";
import HoldingsBrief from "./holdings_brief/HoldingsBrief";
import TradeVolume from "./trade_volume/TradeVolume";
import Margin from "./margin/Margin";
import {ReloadOutlined} from "@ant-design/icons";

function Dashboard(props) {
    const [shouldLoad, setShouldLoad] = useState(false);

    return (
        <div id='container-dashboard'>
            <div id='title-container-dashboard'>
                <h1 id='title-dashboard'>监控中心</h1>
                <Button
                    id='button-load-dashboard'
                    type="text"
                    icon={<ReloadOutlined/>}
                    size="middle"
                    shape="circle"
                    onClick={() => setShouldLoad(true)}
                />
            </div>
            <div id='card-container-dashboard'>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Card bordered={false}>
                            <HoldingsBrief shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <ConvertibleBondWatchdog shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>

                    <Col span={6}>
                        <Card bordered={false}>
                            <StockPondComparison shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <ConvertibleBond shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <TradeVolume shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <Margin shouldLoad={shouldLoad}/>
                        </Card>
                    </Col>

                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Dashboard;
