import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table} from "antd";
import './AbsProfitTable.css';
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const {Column} = Table;


function signNumber(number) {
    if (number >= 0) {
        return '+' + number.toString();
    } else {
        return number.toString();
    }
}

function priceClass(percentage) {
    let priceClass = 'neutral';
    if (percentage > 0) {
        priceClass = 'positive';
    } else if (percentage < 0) {
        priceClass = 'negative';
    }
    return priceClass;
}

function AbsProfitTable(props) {
    const [loading, setLoading] = useState(true);
    const [profitList, setProfitList] = useState([]);
    const [showSecret, setShowSecret] = useState(true);

    useEffect(() => {
        setLoading(true);
        const url = '/absholdingsprofit';
        axios.get(url)
            .then(response => {
                const data = response.data;
                console.log(data);
                let l = data['category_data'];
                l.unshift({
                    abstraction: '总计',
                    earnings_today_percentage: data['earnings_today_percentage'],
                    earnings_today: data['earnings_today'],
                    earnings_percentage: data['dynamic_earnings_percentage'],
                    earnings: data['dynamic_earnings'],
                    current_price: '',
                    holding_cost: '',
                    dynamic_value: data['net_value_total_amount'],
                    amount: data['dynamic_total_amount'],
                });
                setProfitList(l);

                setLoading(false);
            })
    }, []);

    let iconSecretVisibility = <EyeInvisibleOutlined/>;
    if (!showSecret) {
        iconSecretVisibility = <EyeOutlined/>;
    }
    let buttonSecretVisibility = <a onClick={e => {
        setShowSecret(!showSecret);
    }}>{iconSecretVisibility}</a>;

    return (
        <Table
            dataSource={profitList}
            loading={loading}
            pagination={false}
            size={'small'}
            style={{height: '100%', overflow: 'auto'}}
            rowClassName={(r, i) => {
                if (i % 2 === 0) {
                    return 'row-even';
                }
            }}
        >
            <Column title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{flex: "1 1"}}>品种名</span>
                    {buttonSecretVisibility}
                </div>
            }
                    dataIndex={'abstraction'}
                    render={i => <strong>{i}</strong>}/>
            <Column title="今日盈亏"
                    width={'15%'}
                    render={i => {
                        return (
                            <span>
                                <span
                                    className={`profit_percentage_${priceClass(i['earnings_today'])} percentage_today`}>
                                    {signNumber(i['earnings_today_percentage'])}%
                                </span>
                                {showSecret
                                    ? (
                                        <span>
                                            ({signNumber(i['earnings_today'])})
                                        </span>
                                    )
                                    : null}
                            </span>
                        )
                    }}/>
            <Column title="累计盈亏"
                    width={'15%'}
                    render={i => {
                        return (
                            <span>
                                <span className={`profit_percentage_${priceClass(i['earnings'])} percentage_overall`}>
                                    {signNumber(i['earnings_percentage'])}%
                                </span>
                                {showSecret
                                    ? (
                                        <span>
                                            ({signNumber(i['earnings'])})
                                        </span>
                                    )
                                    : null}
                            </span>
                        )
                    }}/>
            <Column title="现价" dataIndex='current_price'/>
            <Column title="成本价" dataIndex='holding_cost'/>
            <Column title="动态持仓" dataIndex='dynamic_value'
                    width={'15%'}
                    render={i => showSecret ? i : '*'}/>
            <Column title="静态成本" dataIndex='amount'
                    width={'15%'}
                    render={i => showSecret ? i : '*'}/>
        </Table>
    );
}

export default AbsProfitTable;
