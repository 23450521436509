import React, {useState} from 'react';
import {Grid, Radio} from "antd";
import ComparisonChart from "./ComparisonChart";

const {useBreakpoint} = Grid;

function IndexPondComparison() {
    const [selectedCode, setSelectedCode] = useState('000985');

    const screens = useBreakpoint();

    return (
        <div style={{padding: screens['sm'] ? 24 : 8, height: '100%'}}>
            <div>
                <Radio.Group onChange={(value) => setSelectedCode(value.target.value)} defaultValue="000985">
                    <Radio.Button value="000985">中证全指</Radio.Button>
                    <Radio.Button value="000906">中证 800</Radio.Button>
                </Radio.Group>
            </div>
            <div style={{
                marginTop: screens['sm'] ? 24 : 8,
                backgroundColor: '#fcfcfc',
                padding: screens['sm'] ? '24px 16px' : 0
            }}>
                <ComparisonChart code={selectedCode}/>
            </div>
        </div>
    );
}

export default IndexPondComparison;