import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Spin} from 'antd';
import {Column} from '@ant-design/charts';
import './TradeVolume.css';
import moment from "moment";

function TradeVolume(props) {
    const [loading, setLoading] = useState(true);
    const [volumes, setVolumes] = useState([]);

    const refreshData = () => {
        setLoading(true);
        const url = `/getindexvolume`;

        axios.get(url)
            .then(response => {
                const data = response.data;
                const volumes = data.map(datum => {
                    return {
                        日期: datum.date,
                        维度: '成交量',
                        值: datum.volume,
                    }
                });
                setVolumes(volumes);
                setLoading(false);
            })
    };

    useEffect(() => {
        if (props.shouldLoad) {
            refreshData();
        }
    }, [props.shouldLoad]);

    const annotations = [];

    let current;
    if (volumes.length > 0) {
        current = volumes[volumes.length - 1];
        annotations.push({
            type: 'line',
            start: ['min', current.值],
            end: ['max', current.值],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
                stroke: 'red',
            },
        });
    }

    const config = {
        data: volumes,
        xField: '日期',
        yField: '值',
        seriesField: '维度',
        autoFit: true,
        lineStyle: {
            lineWidth: 1,
        },
        legend: false,
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: 1,
        },
        xAxis: {
            label: null,
            line: null,
            tickLine: null,
        },
        tooltip: {
            formatter: (datum) => {
                return {name: datum.日期, value: Math.round(datum.值 / 10000) + ' 万手'};
            },
        },
        annotations: annotations,
        meta: {
            ['日期']: {
                sync: false,
            },
        },
    };

    return (
        <div id='container-trade-volume'>
            <div id='titlebar-trade-volume'>
                <span id='title-trade-volume'>沪指成交量</span>
                <span id='title-tail-trade-volume'>{current && moment(current.日期).format('(MM/DD)')}</span>
            </div>
            <div id='subtitlebar-trade-volume'>
                <span id='subtitle-trade-volume'>{current && Math.round(current.值 / 10000)}</span>
                <span id='subtitle-tail-trade-volume'>{current && '万手'}</span>
            </div>
            <div id='volume-chart-container'>
                <Spin id='spin-volume-chart' size='middle' spinning={loading}>
                    <Column id='volume-chart' {...config}/>
                </Spin>
            </div>
        </div>
    );
}

export default TradeVolume;
