import React from 'react';
import IndexConstituentList from "./IndexConstituentList";
import {Grid} from "antd";

const {useBreakpoint} = Grid;

function IndexConstituents() {
    const screens = useBreakpoint();
    return (
        <div style={{padding: screens['sm'] ? 24 : 8, height: '100%'}}>
            <IndexConstituentList style={{height: '20'}}/>
        </div>
    );
}

export default IndexConstituents;