import {Button, Form, Input} from 'antd';
import React from 'react';
import './Login.css';
import axios from "axios";

function Login(props) {
    const onFinish = (values) => {
        console.log('Success:', values);
        const url = '/login';
        axios.post(url, values)
            .then(response => {
                const data = response.data;
                localStorage.setItem('token', data)
                window.location.href = '/';
            })
            .catch(error => {
            })
    };

    return (
        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 600}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{required: true, message: 'Please input your username!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{required: true, message: 'Please input your password!'}]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Login;
