import React, {useState, useEffect} from 'react';
import {Grid, Select} from "antd";
import axios from 'axios';
import {useDebounce} from 'ahooks';

const {useBreakpoint} = Grid;

function SearchIndexSelect(props) {
    const screens = useBreakpoint();

    const [options, setOptions] = useState([]);
    const [keywords, setKeywords] = useState('');
    const debouncedKeywords = useDebounce(keywords, {wait: 500});

    useEffect(() => {
        const url = '/searchindex';
        axios.get(url, {
            params: {
                'keyword': debouncedKeywords,
            }
        }).then(res => {
            const options = res.data.map(c => {
                return {
                    label: `${c.name}（${c.stockCode}）`,
                    value: c.stockCode,
                    data: c,
                }
            });
            setOptions(options);
        });
    }, [debouncedKeywords]);

    const selectId = 'indexSelect-' + global.genRandStr();
    return (
        <Select
            id={selectId}
            showSearch={screens['sm']}
            bordered={true}
            style={{width: '100%'}}
            showArrow={false}
            size={props.size || 'default'}
            placeholder={props.placeholder || '选择指数'}
            onSearch={(value) => {
                setKeywords(value);
            }}
            onChange={(value) => {
                document.getElementById(selectId).blur();
                props.onCodeSelected(value);
            }}
            options={options}
            filterOption={(input, option) => {
                return option.data.stockCode.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.data.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // || option.data.name_pinyin.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        >
        </Select>
    );
}

export default SearchIndexSelect;