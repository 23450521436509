import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table} from "antd";
import './IndexValue.css';
import moment from "moment";

const {Column} = Table;

function format_signed_percentage(percentage) {
    if (percentage === null) {
        return ''
    }
    let value_text = (percentage * 100).toFixed(2).toString() + '%';
    if (percentage >= 0) {
        value_text = '+' + value_text;
    }
    return value_text;
}

function formatPercentage(percentage) {
    return (percentage * 100).toFixed(2).toString() + '%';
}

function trendTag(datum) {
    let gap1Year = datum['percentage_1_year'] - datum['percentage_5_year'];
    let trend = "";
    let trendColor = "#000000";
    if (gap1Year > 0.4) {
        trend = '▲▲';
        trendColor = '#b54343';
    } else if (gap1Year > 0.2) {
        trend = '▲';
        trendColor = '#b54343';
    } else if (gap1Year < -0.4) {
        trend = '▼▼';
        trendColor = '#3b8a36';
    } else if (gap1Year < -0.2) {
        trend = '▼';
        trendColor = '#3b8a36';
    } else {
        trend = '';
        trendColor = '#000000';
    }

    if (trend) {
        return <sup style={{color: trendColor}}>{trend}</sup>;
    } else {
        return "";
    }
}

function IndexValue(props) {
    const [loading, setLoading] = useState(true);
    const [valueList, setValueList] = useState([]);

    useEffect(() => {
        setLoading(true);
        const url = '/indexvalue';
        axios.get(url)
            .then(response => {
                const data = response.data;
                setValueList(data);
                setLoading(false);
            })
    }, []);

    const today = moment();
    const valueAllToday = valueList.every(item => moment(item.date).isSame(today, 'day'));

    return (
        <Table
            dataSource={valueList}
            loading={loading}
            pagination={false}
            size={'small'}
            style={{height: '100%', overflow: 'auto'}}
            rowClassName={record => {
                const estimate_level = record.estimate_level;
                if (estimate_level === 2) {
                    return 'level_very_high';
                }
                if (estimate_level === 1) {
                    return 'level_high';
                }
                if (estimate_level === 0) {
                    return 'level_normal';
                }
                if (estimate_level === -1) {
                    return 'level_low';
                }
                if (estimate_level === -2) {
                    return 'level_very_low';
                }
            }}
        >
            {!valueAllToday && <Column title="日期" dataIndex="date"/>}
            <Column title="代码" dataIndex="code"
                    render={(i, r) => {
                        return <a href={"/indexhistory?code=" + r["code"]}>{i}</a>
                    }}/>
            <Column title="名称" dataIndex="name_cn"
                    render={(i, r) => {
                        return <span><b>{i}</b>{trendTag(r)}</span>
                    }}/>
            <Column title="点位" dataIndex="point"
                    render={i => i !== null ? i.toFixed(2) : '-'}/>
            <Column title="估值" dataIndex="pe"/>
            <Column title="历史百分位" dataIndex="percentage"
                    render={i => <b>{formatPercentage(i)}</b>}/>
            <Column title="距 850 线" dataIndex="distance_to_850"
                    render={i => i <= 0.1 ? <b>{format_signed_percentage(i)}</b> : format_signed_percentage(i)}/>
            <Column title="1 年" dataIndex="percentage_1_year"
                    render={i => formatPercentage(i)}/>
            <Column title="3 年" dataIndex="percentage_3_year"
                    render={i => formatPercentage(i)}/>
            <Column title="5 年" dataIndex="percentage_5_year"
                    render={i => formatPercentage(i)}/>
        </Table>
    )
}

export default IndexValue;
