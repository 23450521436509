import React from 'react';
import {Grid} from "antd";
import './HoldingsOccupation.css';
import HoldingsOccupationChart from "./HoldingsOccupationChart";

const {useBreakpoint} = Grid;

function HoldingsOccupation() {
    const screens = useBreakpoint();
    return (
        <div style={{padding: screens['sm'] ? 24 : 8, width: '100%', height: '100%'}}>
            <div className={screens['sm'] ? 'parent-large' : 'parent-small'}>
                <div className={screens['sm'] ? 'chart-card-large' : 'chart-card-small'} style={{gridArea: 'main'}}>
                    <span className={'card-title-large'} style={{position: screens['sm'] ? 'fixed' : 'relative'}}>品种占比</span>
                    <HoldingsOccupationChart type={'abstraction'} showLegend={false} outerLabel={screens['sm']}/>
                </div>
                <div className={screens['sm'] ? 'chart-card-large' : 'chart-card-small'} style={{gridArea: 'a'}}>
                    <span className={'card-title-small'} style={{position: screens['sm'] ? 'fixed' : 'relative'}}>动态占比</span>
                    <HoldingsOccupationChart type={'dynamic'} showLegend={false} outerLabel={screens['sm']}/>
                </div>
                <div className={screens['sm'] ? 'chart-card-large' : 'chart-card-small'} style={{gridArea: 'b'}}>
                    <span className={'card-title-small'} style={{position: screens['sm'] ? 'fixed' : 'relative'}}>成本占比</span>
                    <HoldingsOccupationChart type={'cost'} showLegend={false} outerLabel={screens['sm']}/>
                </div>
                <div className={screens['sm'] ? 'chart-card-large' : 'chart-card-small'} style={{gridArea: 'c'}}>
                    <span className={'card-title-small'} style={{position: screens['sm'] ? 'fixed' : 'relative'}}>大类占比</span>
                    <HoldingsOccupationChart type={'category'} showLegend={false} outerLabel={screens['sm']}/>
                </div>
                <div className={screens['sm'] ? 'chart-card-large' : 'chart-card-small'} style={{gridArea: 'd'}}>
                    <span className={'card-title-small'} style={{position: screens['sm'] ? 'fixed' : 'relative'}}>账户占比</span>
                    <HoldingsOccupationChart type={'platform'} showLegend={false} outerLabel={screens['sm']}/>
                </div>
            </div>
        </div>
    );
}

export default HoldingsOccupation;