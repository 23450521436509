import React, {useEffect, useState} from 'react';
import {Grid, List} from 'antd';
import './IndexConstituentList.css';
import axios from "axios";
import SearchIndexSelect from "../common/SearchIndexSelect";

const {useBreakpoint} = Grid;

function IndexConstituentList() {
    const screens = useBreakpoint();

    const [selectedFirstIndex, setSelectedFirstIndex] = useState('');
    const [selectedSecondIndex, setSelectedSecondIndex] = useState('');

    const [constituentsFirstIndex, setConstituentsFirstIndex] = useState([]);
    const [constituentsSecondIndex, setConstituentsSecondIndex] = useState([]);

    useEffect(() => {
        if (selectedFirstIndex !== '') {
            const url = '/indexconstituents';
            axios.get(url, {params: {code: selectedFirstIndex}})
                .then(res => setConstituentsFirstIndex(res.data))
        }
    }, [selectedFirstIndex]);

    useEffect(() => {
        if (selectedSecondIndex !== '') {
            const url = '/indexconstituents';
            axios.get(url, {params: {code: selectedSecondIndex}})
                .then(res => setConstituentsSecondIndex(res.data))
        }
    }, [selectedSecondIndex]);

    const secondConstituentCodes = constituentsSecondIndex.map(c => c.stock_code);
    const constituentConjunctions = constituentsFirstIndex.filter(c => secondConstituentCodes.indexOf(c.stock_code) !== -1);
    const codeConjunctions = constituentConjunctions.map(c => c.stock_code);

    return (
        <div className='constituents-container'>
            <div className='constituent-container'>
                <div
                    className='constituent-header'>
                    <SearchIndexSelect
                        size='large'
                        placeholder="选择第一指数"
                        onCodeSelected={code => {
                            setSelectedFirstIndex(code);
                        }}
                    />
                </div>
                <List
                    className='constituent-list'
                    bordered
                    dataSource={constituentsFirstIndex}
                    renderItem={item => (
                        <List.Item
                            className={
                                codeConjunctions.indexOf(item.stock_code) !== -1
                                    ? 'constituent-list-item-disabled'
                                    : 'constituent-list-item'
                            }>
                            <span style={{
                                width: constituentsFirstIndex.length >= 1000 ? 50 : 30,
                                display: 'inline-block'
                            }}>{constituentsFirstIndex.indexOf(item) + 1}</span>
                            {`${item['name']}(${item['capacity_str']})`}
                        </List.Item>
                    )}
                />
            </div>
            {!screens['sm'] ? '' : (
                <div className='constituent-container'>
                    <p className='constituent-header conjunction-header'
                       style={{lineHeight: '40px', fontSize: '16px', fontWeight: 600, textAlign: 'center'}}>
                        共有成分股
                    </p>
                    <List
                        className='constituent-list'
                        bordered
                        dataSource={constituentConjunctions}
                        renderItem={item => (
                            <List.Item>
                                <span style={{
                                    width: constituentConjunctions.length >= 1000 ? 50 : 30,
                                    display: 'inline-block'
                                }}>{constituentConjunctions.indexOf(item) + 1}</span>
                                {`${item['name']}`}
                            </List.Item>
                        )}
                    />
                </div>
            )}
            {!screens['sm'] ? '' : (
                <div className='constituent-container'>
                    <div
                        className='constituent-header'>
                        <SearchIndexSelect
                            size='large'
                            placeholder="选择第二指数"
                            onCodeSelected={code => {
                                console.log(code);
                                setSelectedSecondIndex(code);
                            }}
                        />
                    </div>
                    <List
                        className='constituent-list'
                        bordered
                        dataSource={constituentsSecondIndex}
                        renderItem={item => (
                            <List.Item
                                className={
                                    codeConjunctions.indexOf(item.stock_code) !== -1
                                        ? 'constituent-list-item-disabled'
                                        : 'constituent-list-item'
                                }>
                                <span style={{
                                    width: constituentsSecondIndex.length >= 1000 ? 50 : 30,
                                    display: 'inline-block'
                                }}>{constituentsSecondIndex.indexOf(item) + 1}</span>
                                {`${item['name']}(${item['capacity_str']})`}
                            </List.Item>
                        )}
                    />
                </div>
            )}
        </div>
    );
}

export default IndexConstituentList;
