import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from "antd";
import axios from 'axios';

function loadTradeRecords(code, setTradeRecords) {
    if (!code) {
        return;
    }
    axios.get('/traderecords', {
        params: {
            index_code: code,
            include_price: '',
        }
    }).then(response => {
        const data = response.data;
        setTradeRecords(data);
    });
}

function loadHistories(type, code, fromDate, toDate, setHistoriesLoading, setHistories) {
    if (!code) {
        return;
    }
    setHistoriesLoading(true);
    setHistories([]);
    let url = '/indexhistories';
    if (type === 'point') {
        url = '/indexhistories';
    } else if (type === 'value') {
        url = '/valuehistories';
    }
    axios.get(url, {
        params: {
            code: code,
            from_date: fromDate.format('YYYY-MM-DD'),
            to_date: toDate.format('YYYY-MM-DD'),
        }
    }).then(response => {
        const data = response.data;

        const valueData = data.histories.map(datum => {
            return {
                日期: datum.date,
                维度: type === 'point' ? '点位' : data.value_key.toUpperCase(),
                点位: datum.value,
            }
        });
        const avgData = data.avg_all.map(datum => {
            return {
                日期: datum.date,
                维度: '历史均值',
                点位: datum.value,
            }
        });
        const avg1Data = data.avg_1_year.map(datum => {
            return {
                日期: datum.date,
                维度: '一年均值',
                点位: datum.value,
            }
        });
        const avg3Data = data.avg_3_years.map(datum => {
            return {
                日期: datum.date,
                维度: '三年均值',
                点位: datum.value,
            }
        });
        const avg5Data = data.avg_5_years.map(datum => {
            return {
                日期: datum.date,
                维度: '五年均值',
                点位: datum.value,
            }
        });
        const avg850Data = data.avg_850_days.map(datum => {
            return {
                日期: datum.date,
                维度: '850 日均值',
                点位: datum.value,
            }
        });

        const chartData = valueData.concat(avgData)
            .concat(avg1Data)
            .concat(avg3Data)
            .concat(avg5Data)
            .concat(avg850Data);

        setHistories(chartData);
        setHistoriesLoading(false);
    });
}

function buildChart(historiesLoading, histories, tradeRecords) {
    const max = Math.max(...histories.map(h => h.点位 === null ? -999999 : h.点位));
    const min = Math.min(...histories.map(h => h.点位 === null ? 999999 : h.点位));
    const yPadding = (max - min) * 0.05;

    const annotations = [];
    for (let record of tradeRecords) {
        const history_that_day = histories.find(h => h.日期 === record.date);
        annotations.push({
            type: 'dataMarker',
            position: [record.date, history_that_day?.点位],
            text: {
                content: '',
                style: {
                    textAlign: 'left',
                },
            },
            point: {
                style: {
                    fill: record.price === 0 ? '#FFE600' : record.purchase ? '#3da742' : '#ff6827',
                    stroke: null,
                },
            },
            autoAdjust: false,
        });
    }

    const config = {
        data: histories,
        xField: '日期',
        yField: '点位',
        seriesField: '维度',
        lineStyle: {
            lineWidth: 1,
        },
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: isFinite(yPadding) ? min - yPadding : 0,
            maxLimit: isFinite(yPadding) ? max + yPadding : 0,
        },
        xAxis: {
            line: histories.length > 0 ? {
                style: {
                    stroke: '#e6e6e6'
                }
            } : null,
            tickLine: null,
            tickMethod: scale => {
                const ticks = [];

                let prevDate = new Date(scale.values[0]);
                scale.values.forEach(dateString => {
                    const d = new Date(dateString);
                    if (d.getFullYear() !== prevDate.getFullYear()) {
                        ticks.push(dateString);
                        prevDate = d;
                    }
                });

                return ticks;
            },
            label: {
                formatter: date => new Date(date).getFullYear(),
            },
        },
        annotations: annotations,
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + defaultContent.toFixed(4);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
        },
        padding: [24, 24, 60, 24],
        slider: {
            start: 0,
            end: 1,
        },
        limitInPlot: false,
        meta: {
            ['日期']: {
                sync: false,
            },
        },
    };
    return (
        <div>
            <Spin size='middle' spinning={historiesLoading}>
                <Line {...config}/>
            </Spin>
        </div>
    );
}

function HistoryChart(props) {
    const [historiesLoading, setHistoriesLoading] = useState(false);
    const [histories, setHistories] = useState([]);
    const [tradeRecords, setTradeRecords] = useState([]);

    useEffect(() => {
        loadHistories(props.type, props.code, props.fromDate, props.toDate, setHistoriesLoading, setHistories)
    }, [props.type, props.code, props.fromDate, props.toDate]);

    useEffect(() => {
        if (props.showTradeRecord) {
            loadTradeRecords(props.code, setTradeRecords);
        }
    }, [props.code, props.showTradeRecord]);

    return buildChart(historiesLoading, histories, tradeRecords);
}

export default HistoryChart;
