import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table, message} from "antd";
import moment from 'moment';
import './DividendDynamics.css';
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const {Column} = Table;

const today = () => moment().startOf('day');
const afterToday = (d) => moment(d) > today();
const isOrAfterToday = (d) => d === null || moment(d) >= today();
const isToday = (d) => moment(d).isSame(today());

function DividendDynamics(props) {
    const [loading, setLoading] = useState(true);
    const [dynamicsList, setDynamicsList] = useState([]);
    const [showSecret, setShowSecret] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setLoading(true);
        const url = '/dividendhistories';
        axios.get(url)
            .then(response => {
                const data = response.data;
                setDynamicsList(data);
                console.log(data);
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        axios.get('/dividendupdatetime')
            .then(response => {
                const data = response.data;
                console.log(data);
                let update_time = data["update_time"];
                messageApi.info('数据更新于：' + update_time, 3)
            })
    }, []);

    let iconSecretVisibility = <EyeInvisibleOutlined />;
    if (!showSecret) {
        iconSecretVisibility = <EyeOutlined />;
    }
    let buttonSecretVisibility = <a onClick={e => {
        setShowSecret(!showSecret);
    }}>{iconSecretVisibility}</a>;

    return (
        <>
            {contextHolder}
            <Table
                dataSource={dynamicsList}
                loading={loading}
                pagination={false}
                size={'small'}
                style={{height: '100%', overflow: 'auto'}}
                rowClassName={record => {
                    if (record.payment_date === null || afterToday(record.payment_date)) {
                        return 'new-row';
                    } else if (isToday(record.payment_date)) {
                        return 'today-row';
                    } else {
                        return 'normal-row';
                    }
                }}
            >
                <Column title="代码" dataIndex="code"/>
                <Column title="名称" dataIndex="name"/>
                <Column title="登记日" dataIndex="record_date"
                        render={i => i || '-'}/>
                <Column title="除权日" dataIndex="ex_dividend_date"
                        render={i => i || '-'}/>
                <Column title="派息日" dataIndex="payment_date"
                        render={i => i || '-'}/>
                <Column title="派息额" dataIndex="dividend_per_share"/>
                <Column title="送转比例" dataIndex="bonus_it_ratio"
                        render={i => i || '-'}/>
                <Column title={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{flex: "1 1"}}>当前持股</span>{buttonSecretVisibility}
                    </div>
                }
                        dataIndex="shares_holding"
                        render={i => showSecret ? i : '*'}/>
                <Column title="预估派息" dataIndex="estimate_dividend"
                        render={(i, r) => showSecret ? (isOrAfterToday(r.payment_date) ? i : '-') : '*'}/>
            </Table>
        </>
    );
}

export default DividendDynamics;
