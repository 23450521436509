import React, {useEffect, useState} from 'react';
import {Grid} from "antd";
import {Pie} from '@ant-design/charts';
import axios from "axios";

const {useBreakpoint} = Grid;

function HoldingsOccupationChart(props) {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const screens = useBreakpoint();

    useEffect(() => {
        setDataLoading(true);
        let url = '/abstractionoccupations';
        if (props.type === 'abstraction') {
            url = '/abstractionoccupations';
        } else if (props.type === 'dynamic') {
            url = '/dynamicoccupations';
        } else if (props.type === 'cost') {
            url = '/costoccupations';
        } else if (props.type === 'platform') {
            url = '/platformoccupations';
        } else if (props.type === 'category') {
            url = '/categoryoccupations';
        }
        axios.get(url)
            .then(response => {
                setData(response.data);
                setDataLoading(false);
            })
    }, []);

    const chartData = data.map(d => {
        let key = d.abstraction;
        if (props.type === 'abstraction') {
            key = d.abstraction;
        } else if (props.type === 'dynamic') {
            key = d.property.local_name;
        } else if (props.type === 'cost') {
            key = d.abstraction;
        } else if (props.type === 'platform') {
            key = d.platform;
        } else if (props.type === 'category') {
            key = d.category;
        }
        return {
            'key': key,
            'amount': d.amount,
        }
    });

    const config = {
        legend: props.showLegend ? {
            position: 'bottom',
            flipPage: false,
        } : false,
        label: {
            type: props.outerLabel ? 'outer' : 'inner',
            autoRotate: false,
            content: '{name}\n{percentage}',
            offset: screens['sm'] ? 25 : 0,
        },
        tooltip: {
            formatter: (datum) => {
                return {name: datum.key, value: datum.amount + ' 元'};
            },
        },
        data: chartData,
        autoFit: true,
        radius: 0.6,
        padding: 0,
        appendPadding: 0,
        colorField: 'key',
        angleField: 'amount',
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
    };
    return (
        <Pie {...config} />
    )
}

export default HoldingsOccupationChart;